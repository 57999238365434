import Boxes from "../components/boxes";
import Layout from "../components/layout";
import Banner from "../components/banner";
import CarouselNewClothes from "../components/carouselNews";
import LogoCarousel from "../components/carouselLogo";
import InfoCards from '../components/infoCards';
// import ScrollTextBanner from "../components/scroll-banner";
import information from "../fixtures/information.json";
import logo from "../fixtures/carouselLogo.json";

const ComeIn = () => {
  return (
    <Layout
      fbLink="https://www.facebook.com/Comeinvoiron"
      instaLink="https://www.instagram.com/comein_voiron"
    >
      <main className="come-in">
        <Banner
          bgimage={require("../styles/assets/banner_background_come_in.webp")}
          bgimageSmall={require("../styles/assets/banner_background_come_in_mobile.webp")}
          color="#000"
          title="COME IN"
        />
          {/* <div className="banner-text">
      <ScrollTextBanner text="Des nouveautés en permanence, restez connectez !" />
    </div> */}
        <div className="cards">
          <div className="cards-shop">
            <div className="shop__video">
              <video
                title="Boutique Come in Voiron"
                autoPlay
                loop
                muted
              >
                <source src="/assets/video/come-in-boutique.mp4" />
              </video>
            </div>

            <article className="text-card">
              <h3>COME IN VETEMENT & ACCESSOIRE FEMME</h3>
              <p>
                Pour vous mesdames nous vous proposons notre boutique de
                prêt-à-porter et accessoires multimarques pour les femmes :
                Only, Pieces, Jacqueline de Yong... Découvrez notre large
                selection en magasin , avec une séléction au top des tendances
                et en plus chaque semaine nous reçevons de la nouveauté afin de
                repondre au plus près de vos envies !
              </p>
              <p className="descr-shop">
                Chez COME IN, nous privilegions la proximité et l'écoute de nos
                clientes afin de vous proposer un shopping personnalisé au plus
                près de vos demandes.
              </p>
            </article>
          </div>
        </div>
        <section className="work-with">
          <hr />
          <h3>Nous travaillons avec</h3>
          <LogoCarousel logo={logo.comeIn} />
          <hr />
        </section>

        <section className="cards-categories">
          <h3> Découvrez notre magasin </h3>
          <Boxes info={information.come} />
        </section>

        <article className="news">
          <h3>Nouveautés de la collection printemps 2024</h3>
          <CarouselNewClothes information={information} />
        </article>

        <div className="contact-info__style">
          <article className="contact-infos">
            <h3 className="contact-infos__title">Contact & infos</h3>
            <InfoCards />
          </article>
        </div>
      </main>
    </Layout>
  );
};
export default ComeIn;
