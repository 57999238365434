import React, { useState, useEffect } from 'react';
// import logo from '../fixtures/carouselLogo.json';

const LogoCarousel = ({logo}) => {
    const [offset, setOffset] = useState(0);
    const initialScrollSpeed = 1;
    const [scrollSpeed, setScrollSpeed] = useState(initialScrollSpeed);
    const totalLogoWidth = logo.length * (100);

    // Ajouter un ralentissement a l'hover
    // const handleMouseEnter = () => {
    //     setScrollSpeed(0.5);
    // };

    const handleMouseLeave = () => {
        setScrollSpeed(initialScrollSpeed);
    };

    // increases the number of tables per screen size
    const getNumberOfBlocks = () => {
        const width = window.innerWidth;
        if (width <= 1023) {
            return 2;
        } else if (width <= 1920) {
            return 3;
        } else if (width <= 2560) {
            return 4;
        } else {
            return 5;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setOffset((prevOffset) => prevOffset <= -totalLogoWidth ? 0 : prevOffset - scrollSpeed);
        }, 1000 / 40); // 60 FPS

        return () => clearInterval(interval);
    }, [scrollSpeed, totalLogoWidth]);

    return (
        <div
            className="all-logo"
            // onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {[...Array(getNumberOfBlocks())].map((_, blockIndex) => (
                <div
                    key={blockIndex}
                    className="block-logo"
                    style={{
                        transform: `translateX(${offset}px)`,
                    }}
                >
                    {logo.map((logo, index) => (
                        <div className="logo" key={index}>
                            <img src={logo.image} alt={logo.alt} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LogoCarousel;