import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import ComeIn from "./pages/comeIn";
import ComeInMen from "./pages/comeInMen";
import Popin from "./pages/popin";
import Home from './pages/home';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/'exact element={<Home/>} />
          <Route path='/come-in'exact element={<ComeIn/>} />
          <Route path='/come-in-men' exact element={<ComeInMen/>} />
          <Route path ='/popin' exact element={<Popin/>}/>
          <Route path='/*' exact element={<Navigate to="/" replace/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
