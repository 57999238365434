import React, { useState, useEffect } from 'react';

const CarouselNewClothes = ({ information }) => {
    const [offset, setOffset] = useState(0);
    const initialScrollSpeed = 1.5;
    const [scrollSpeed, setScrollSpeed] = useState(initialScrollSpeed);
    const calibration = (window.innerWidth <= 768 ? 279.5 : 529.5);
    const totalLogoWidth = information.news.length * calibration;

    const handleMouseEnter = () => {
        setScrollSpeed(0.5);
    };

    const handleMouseLeave = () => {
        setScrollSpeed(initialScrollSpeed);
    };

    // increases the number of tables per screen size
    const getNumberOfBlocks = () => {
        const width = window.innerWidth;
        if (width <= 1023) {
            return 2;
        } else if (width <= 1920) {
            return 3;
        } else if (width <= 2560) {
            return 4;
        } else {
            return 5;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setOffset((prevOffset) => prevOffset <= -totalLogoWidth ? 0 : prevOffset - scrollSpeed);
        }, 1000 / 40); // 60 FPS

        return () => clearInterval(interval);
    }, [scrollSpeed, totalLogoWidth]);


    return (
        <div
            className="all-block"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {[...Array(getNumberOfBlocks())].map((_, blockIndex) => (
                <div
                    key={blockIndex}
                    className="block-clothes"
                    style={{
                        transform: `translateX(${offset}px)`,
                    }}
                >
                    {information.news.map((newItem, i) => (
                        <article className='clothe__card' key={i}>
                            <img src={newItem.img.src} alt={newItem.img.alt} />
                            <section className='clothe__info'>
                                <h4>€ <span>{newItem.price}</span></h4>
                                <div className='clothe__desc'>
                                    <h5>{newItem.title}</h5>
                                    <p>{newItem.desc}</p>
                                </div>
                            </section>
                        </article>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default CarouselNewClothes;