import React from 'react';
import { motion } from 'framer-motion';

const InfoCard = () => {
    const infoCard = [
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='currentColor' d='m17.95 15.463l-2.138-2.113l.713-.708l1.425 1.42l3.525-3.525l.713.688zM11 19.653l-1.156-1.041q-2.28-2.087-3.799-3.593q-1.518-1.506-2.413-2.679q-.895-1.173-1.264-2.146Q2 9.221 2 8.225q0-1.908 1.296-3.201Q4.592 3.731 6.5 3.731q1.32 0 2.475.675T11 6.369q.87-1.288 2.025-1.963T15.5 3.73q1.737 0 2.948 1.08q1.212 1.08 1.48 2.689H18.9q-.238-1.23-1.19-2t-2.21-.77q-1.217 0-2.21.688q-.992.688-1.888 2.082h-.804q-.91-1.413-1.907-2.091Q7.694 4.73 6.5 4.73q-1.463 0-2.482.997Q3 6.725 3 8.225q0 .844.35 1.713q.35.87 1.25 2.001t2.45 2.658Q8.6 16.123 11 18.3q.765-.69 1.506-1.363q.74-.674 1.323-1.231l.11.11l.247.247l.247.247l.11.11q-.59.557-1.314 1.208q-.725.65-1.471 1.341z'/></svg>",
            "title": "COME IN",
            "desc": "Vêtements & accessoires pour femme",
            "location": "Sur Voiron"
        },
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' ><path fill='currentColor' d='M6.615 21q-.69 0-1.152-.462Q5 20.075 5 19.385V8.615q0-.69.463-1.152Q5.925 7 6.615 7H8.5v-.5q0-1.458 1.021-2.479T12 3q1.458 0 2.479 1.021T15.5 6.5V7h1.885q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h10.77q.23 0 .423-.192q.192-.193.192-.423V8.615q0-.23-.192-.423Q17.615 8 17.385 8H15.5v2.5q0 .213-.143.357Q15.213 11 15 11t-.357-.143q-.143-.144-.143-.357V8h-5v2.5q0 .213-.143.357Q9.213 11 9 11t-.357-.143Q8.5 10.713 8.5 10.5V8H6.615q-.23 0-.423.192Q6 8.385 6 8.615v10.77q0 .23.192.423q.193.192.423.192M9.5 7h5v-.5q0-1.056-.722-1.778T12 4q-1.056 0-1.778.722T9.5 6.5zM6 20V8z'/></svg>",
            "title": "SINCE 2017",
            "desc": "Prêt à porter pour femme de marques",
            "location": "Only, Vila, YAS , JDY, Pieces"
        },
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' ><g fill='none' stroke='currentColor'> <path strokeLinecap='round' strokeLinejoin='round' d='M14.5 21v-5a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v5' /><path d='M5 11v6c0 1.886 0 2.828.586 3.414C6.172 21 7.114 21 9 21h6c1.886 0 2.828 0 3.414-.586C19 19.828 19 18.886 19 17v-6M4.621 4.515c.182-.728.273-1.091.544-1.303C5.437 3 5.812 3 6.562 3h10.876c.75 0 1.125 0 1.397.212c.27.212.362.575.544 1.303l1.203 4.814c.097.388.146.581.135.739a1 1 0 0 1-.69.883c-.15.049-.354.049-.763.049c-.533 0-.8 0-1.023-.052a2 2 0 0 1-1.393-1.18c-.089-.212-.132-.47-.217-.983c-.024-.144-.036-.216-.05-.235a.1.1 0 0 0-.162 0c-.014.019-.026.09-.05.235l-.081.489A2 2 0 0 1 14.352 11h-.204a2 2 0 0 1-1.936-1.726l-.081-.49c-.024-.143-.036-.215-.05-.234a.1.1 0 0 0-.162 0c-.014.019-.026.09-.05.235l-.081.489A2 2 0 0 1 9.852 11h-.204A2 2 0 0 1 7.73 9.374a1.386 1.386 0 0 1-.018-.1l-.081-.49c-.024-.143-.036-.215-.05-.234a.1.1 0 0 0-.162 0c-.014.019-.026.09-.05.235c-.085.514-.128.77-.217.983a2 2 0 0 1-1.392 1.18C5.536 11 5.27 11 4.736 11c-.409 0-.613 0-.763-.049a1 1 0 0 1-.69-.883c-.01-.158.038-.351.135-.739z' /></g></svg>",
            "title": "ADRESSE",
            "desc": "1 Place de Lattre de Tassigny",
            "location": "Voiron (38500)"
        },
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' ><path fill='currentColor' d='M7.615 22q-.69 0-1.152-.462Q6 21.075 6 20.385V3.615q0-.69.463-1.152Q6.925 2 7.615 2h8.77q.69 0 1.152.463q.463.462.463 1.152v16.77q0 .69-.462 1.152q-.463.463-1.153.463zM7 19.5v.885q0 .23.192.423q.193.192.423.192h8.77q.23 0 .423-.192q.192-.193.192-.423V19.5zm0-1h10v-13H7zm0-14h10v-.885q0-.23-.192-.423Q16.615 3 16.385 3h-8.77q-.23 0-.423.192Q7 3.385 7 3.615zm0 0V3zm0 15V21z' /></svg>",
            "title": "TÉLÉPHONE",
            "desc": "04 .76 .07 .83 .15",
            "location": ""
        },
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 14 14' ><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M2 7H.5m2.96-3.54L2.4 2.4m1.06 8.14L2.4 11.6M12 7h1.5m-2.96 3.54l1.06 1.06M7 12v1.5m3.54-10.04L11.6 2.4M7 2V.5' /></svg>",
            "title": "HORAIRE :",
            "desc": "Lundi : 14:00-18:00 Mardi - Samedi : 09:30-19:00 Dimanche : fermé",
            "location": ""
        },
        {
            "img": "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' ><path fill='currentColor' d='M13.654 21v-2.21l5.96-5.934l2.19 2.204l-5.94 5.94zm6.884-5.94l-.925-.945zm-6 5.055h.95l3.468-3.473l-.45-.494l-.475-.47l-3.493 3.487zM5 21V3h9.5L19 7.5v2.596h-1V8h-4V4H6v16h5.27v1zm13.506-4.852l-.475-.47l.925.964z'/></svg>",
            "title": "SIRET",
            "desc": "52954858800050",
            "location": "N° d'établissement (NIC) 00050"
        }
    ]
    return (
        <div className="infos">
            {infoCard.map((card, i) => (
                <motion.div whileHover={{ scale: 1.1 }} key={i} className="info__card">
                    <div className='info__card-img' dangerouslySetInnerHTML={{ __html: card.img }} />
                    <h4>{card.title}</h4>
                    <p>{card.desc}</p>
                    <p>{card.location}</p>
                </motion.div>
            ))}
        </div>
    )
}

export default InfoCard;