import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Clock = () => {
    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();

    let interval;

    const countDown = () => {
        const destination = new Date('Mar 24, 2025').getTime()
        interval = setInterval(() => {
            const now = new Date().getTime()
            const different = destination - now;
            const days = Math.floor(different / (1000 * 60 * 60 * 24));
            const hours = Math.floor(different % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
            const minutes = Math.floor(different % (1000 * 60 * 60) / (1000 * 60));
            const seconds = Math.floor(different % (1000 * 60) / 1000);

            if (destination < 0) clearInterval(interval.current);
            else {
                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        })
    }

    useEffect(() => {
        // eslint-disable-next-line
        countDown();
    }, []);

    return <section className='discount'>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><g><path fill="#A7A9AF50" d="M16.5 6A3.5 3.5 0 0 1 20 9.442c.09.865.077 2.141-.508 3.614c-.598 1.506-1.764 3.148-3.892 4.744a1 1 0 1 1-1.2-1.6c1.564-1.173 2.46-2.313 2.973-3.31A3.5 3.5 0 1 1 16.5 6m-9 0A3.5 3.5 0 0 1 11 9.442c.09.865.077 2.141-.508 3.614c-.597 1.506-1.764 3.148-3.892 4.744a1 1 0 1 1-1.2-1.6c1.564-1.173 2.46-2.313 2.973-3.31A3.5 3.5 0 1 1 7.5 6" /></g></svg>
        <div className="vertical-line"></div>
        <h3>NE MANQUEZ PAS LES REMISES DE -20% SUR LA COLLECTION </h3>
        <div className='time'>
            <div className='time__block'>
                <h2>{days}</h2>
                <h3>Days</h3>
            </div>
            <div className='time__block'>
                <h2>{hours}</h2>
                <h3>Hours</h3>
            </div>
            <div className='time__block'>
                <h2>{minutes}</h2>
                <h3>Minutes</h3>
            </div>
            <div className='time__block'>
                <h2>{seconds}</h2>
                <h3>Seconds</h3>
            </div>
        </div>
        <Link to="/come-in">
            <button>En savoir plus</button>
        </Link>
        <p>Vivez votre moment guchi guchi avec notre nouvelle gamme de produit</p>
        <div className="vertical-line"></div>
    </section>
}

export default Clock