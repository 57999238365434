import Layout from "../components/layout";
import Boxes from "../components/boxes";
import Banner from "../components/banner";
import CarouselNewClothes from '../components/carouselNews';
import LogoCarousel from "../components/carouselLogo";
import InfoCards from '../components/infoCards';
// import ScrollTextBanner from "../components/scroll-banner";
import information from "../fixtures/information.json";
import logo from "../fixtures/carouselLogo.json";

const ComeInMen = () => {
  return (
    <Layout
      fbLink="https://www.facebook.com/comeinhomme"
      instaLink="https://www.instagram.com/comeinhomme_voiron"
    >
      <main className="come-in-men">
        <Banner
          bgimage={require("../styles/assets/banner_background_come_in_homme.webp")}
          bgimageSmall={require("../styles/assets/banner_background_come_in_homme_mobile.webp")}
          color="#fff"
          title="COME IN HOMME"
        />
    {/* <div className="banner-text">
      <ScrollTextBanner text="Des nouveautés en permanence, restez connectez !" />
    </div> */}
        <div className="cards">
          <div className="cards-shop">
            <img alt="come-in" src="/assets/images/shop_men.jpg" />
            <article className="text-card">
              <h3>COME IN VETEMENT & ACCESSOIRE HOMME</h3>
              <p className="descr-shop">
                Bienvenue chez Come In Vêtement & Accessoire Homme, votre
                destination ultime pour le style masculin de qualité et
                l'élégance intemporelle. Situé au cœur de Voiron, notre magasin
                est l'adresse incontournable pour les hommes soucieux de leur
                apparence et à la recherche d'un vestiaire à la fois tendance et
                sophistiqué.<br></br> Plongez dans l'univers de la mode masculine
                raffinée en découvrant notre vaste collection de prêt-à-porter.
                Nous travaillons avec Tommy Hilfiger, Eden Park ou encore
                Selected.
              </p>
            </article>
          </div>
        </div>
        <section className="work-with">
          <hr />
          <h3>Nous travaillons avec</h3>
          <LogoCarousel logo={logo.comeInMen} />
          <hr />
        </section>

        <section className="cards-categories">
          <h2> Découvrez notre magasin pour homme </h2>
          <Boxes info={information.comeMen} />
        </section>

        <article className='news'>
          <h3>Nouveautés de la collection printemps 2024</h3>
          <CarouselNewClothes information={information} />
        </article>

        <div className="contact-info__style">
          <article className="contact-infos">
            <h3 className="contact-infos__title">Contact & infos</h3>
            <InfoCards />
          </article>
        </div>
      </main>
    </Layout>
  );
};
export default ComeInMen;
