import React, { useRef } from "react";
import Layout from "../components/layout";
import { NavLink } from "react-router-dom";
import LogoCarousel from "../components/carouselLogo";
import Banner from "../components/banner";
import Clock from "../components/clock";
import information from "../fixtures/information.json";
import logo from "../fixtures/carouselLogo.json";
import CarouselNewClothes from "../components/carouselNews";
import { animated, useSpring } from "@react-spring/web";
import { useIntersection } from "react-use";
// import ScrollTextBanner from "../components/scroll-banner";

const AnimatedHeading = ({ title }) => {
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  const styles = useSpring({
    opacity: intersection && intersection.intersectionRatio >= 0.5 ? 1 : 0,
    transform:
      intersection && intersection.intersectionRatio >= 0.5
        ? "translateX(0)"
        : "translateX(50%)",
    config: { duration: 1000 },
  });

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <animated.h3 style={styles}>{title}</animated.h3>
    </div>
  );
};

const AnimatedElement = ({ children }) => {
  const ref = React.useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  const springProps = useSpring({
    opacity: intersection && intersection.intersectionRatio >= 0.1 ? 1 : 0,
    transform:
      intersection && intersection.intersectionRatio >= 0.1
        ? "translateY(0)"
        : "translateY(10px)",
    config: { tension: 40, friction: 20 },
  });

  return (
    <animated.div ref={ref} style={springProps}>
      {children}
    </animated.div>
  );
};

const Home = () => {
  return (
    <Layout
      fbLink="https://www.facebook.com/Comeinvoiron"
      instaLink="https://www.instagram.com/comein_voiron"
    >
      <main className="home">
        <Banner
          bgimage={require("../styles/assets/banner_background.webp")}
          bgimageSmall={require("../styles/assets/banner_background_mobile.webp")}
          title="HOME"
        />
            {/* <div className="banner-text">
      <ScrollTextBanner text="Des nouveautés en permanence, restez connectez !" />
    </div> */}
        <section className="shop">
          {information.shop.map((shop, i) => (
            <article className="shop__card" key={i}>
              <img src={shop.image.src} alt={shop.image.alt} />
              <div className="shop__info">
                {/* // <h3>{shop.title}</h3> */}
                <AnimatedHeading title={shop.title} />
                <p>{shop.description}</p>
                <NavLink to={shop.link} aria-label="Accédez à la boutique">
                  READ MORE
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="CurrentColor"
                      d="m13.692 17.308l-.707-.72l4.088-4.088H5v-1h12.073l-4.088-4.088l.707-.72L19 12z"
                    />
                  </svg>
                </NavLink>
              </div>
            </article>
          ))}
        </section>

        <section className="product-range">
          <h3>Notre gamme de produit</h3>
          <div className="product-range__content">
            <AnimatedElement>
              <div className="product__block">
                <div className="block__img long">
                  <img
                    src="assets/images/clothing/popin_1.webp"
                    alt="Accessoires pop'in pour femme"
                  />
                </div>
                <div className="block__color"></div>
                <div className="block__img small">
                  <img
                    src="assets/images/clothing/come_in_2.webp"
                    alt="Vêtements Only chez come in femme"
                  />
                </div>
              </div>
            </AnimatedElement>
            <AnimatedElement>
              <div className="product__block">
                <div className="block__color"></div>
                <div className="block__img small">
                  <img
                    src="assets/images/clothing/tommy_hilfiger_2.jpg"
                    alt="casquette tommy hilfiger"
                  />
                </div>
                <div className="block__img long">
                  <img
                    src="assets/images/clothing/come_in_men.webp"
                    alt="Polo tommy hilfiger empilés"
                  />
                </div>

                <div className="block__color"></div>
              </div>
            </AnimatedElement>
            <AnimatedElement>
              <div className="product__block">
                <div className="block__img small">
                  <img
                    src="assets/images/clothing/come_in_1.webp"
                    alt="rangements vêtements magasins come in femme"
                  />
                </div>

                <div className="block__color"></div>
                <div className="block__img long">
                  <img
                    src="assets/images/clothing/eden_park_2.jpg"
                    alt="chaussette eden park"
                  />
                </div>
              </div>
            </AnimatedElement>
          </div>
        </section>
        <section className="work-with">
          <hr />
          <h3>Nous travaillons avec</h3>
          <LogoCarousel logo={logo.home} />
          <hr />
        </section>
        <Clock />
        <article className="news">
          <h3>Nouveautés de la collection printemps 2024</h3>
          <CarouselNewClothes information={information} />
        </article>
        <div className="google-review">
          <div
            className="elfsight-app-ecd4e181-197a-4e0c-b449-ef99c2772c08"
            data-elfsight-app-lazy
          ></div>
        </div>
      </main>
    </Layout>
  );
};

export default Home;