import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

const Boxes = ({ info }) => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".box");
      const elementPosition = window.scrollY + window.innerHeight;

      elements.forEach((el, i) => {
        const cardPosition = el.offsetTop + el.offsetHeight;
        if (elementPosition >= cardPosition) {
          controls.start(i => ({
            opacity: 1,
            y: 0,
            transition: {
              delay: i * 0.1, // Ajoute un délai à chaque carte
              duration: 0.5,
              ease: "easeOut"
            }
          }));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <div className="product__boxes">
      {info.map((img, i) => (
        <motion.div
          custom={i}
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          className="box"
          key={i}
        >
          <img alt={img.alt} src={img.src} />
          <article className="text-box">
            <p>{img.title}</p>
          </article>
        </motion.div>
      ))}
    </div>
  );
};

export default Boxes;
