import React, { useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";


const Layout = ({ children, fbLink, instaLink }) => {
  const menuRef = useRef(null)
  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  useEffect(() => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      iframe.addEventListener('scroll', () => {
      }, { passive: true });
    });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  

  return (
    <>
      <header id="header">
        <nav>
          <div className="nav-top">
            <Link to="tel:+336847447" aria-label='Contactez-moi à ce numéro'> 04.76.07.83.15</Link>
            <Link to={instaLink} target="_blank" aria-label='Voici mon instagram principal'>
              <span><i className="ri-instagram-line"></i></span>
            </Link>
            <Link to={fbLink} target="_blank" aria-label='Voici mon facebook principal'>
              <span><i className="ri-facebook-line"></i></span>
            </Link>
          </div>
          <div className="nav-bottom">
            <h1>
              <img src="/assets/logo/logo-come-in.png" alt="logo de l'entreprise come in" />
            </h1>
            <div className="nav-bottom__link">
              <div className="navigation" ref={menuRef} onClick={menuToggle}>
                <ul>
                  <li>
                    <NavLink to="/" aria-label="Accueil">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/come-in" aria-label="Page Come in">Come in</NavLink>
                  </li>
                  <li>
                    <NavLink to="/come-in-men" aria-label="Page Come in homme">Come in homme</NavLink>
                  </li>
                  <li>
                    <NavLink to="/popin" aria-label="Page Pop'in">Pop'in</NavLink>
                  </li>
                </ul>
              </div>
              <div className="mobile__menu">
                <span onClick={menuToggle}>
                  <i className="ri-menu-line"></i>
                </span>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {children}
      <footer>
        <div className="footer__info">
          <article className="footer__shop">
            <h2>NOS MAGASINS</h2>
            <div className="footer-block__link">
              <article className="footer-block__shop">
                <h3>Come In</h3>
                <div className="shop__location">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" d="M22 22H2" /><path strokeLinecap="round" d="M20 22V11M4 22V11" opacity=".5" /><path strokeLinejoin="round" d="M16.528 2H7.472c-1.203 0-1.804 0-2.287.299c-.484.298-.753.836-1.29 1.912L2.49 7.76c-.324.82-.608 1.786-.062 2.479A2 2 0 0 0 6 9a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 0 0 3.571 1.238c.546-.693.262-1.659-.062-2.479l-1.404-3.548c-.537-1.076-.806-1.614-1.29-1.912C18.332 2 17.731 2 16.528 2Z" /><path strokeLinecap="round" d="M9.5 21.5v-3c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C10.598 16 11.065 16 12 16s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75v3" opacity=".5" /></g></svg>
                  <p> 1 Place de Lattre de Tassigny 38500 Voiron</p>
                </div>
                <div className="shop__opening">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm147.1 371.1c-19.1 19.1-41.4 34.1-66.1 44.6-25.6 10.8-52.9 16.3-81 16.3s-55.3-5.5-81-16.3c-24.8-10.5-47-25.5-66.1-44.6C89.8 384 74.8 361.7 64.3 337 53.5 311.3 48 284.1 48 256s5.5-55.3 16.3-81c10.5-24.8 25.5-47 44.6-66.1C128 89.8 150.3 74.8 175 64.3 200.7 53.5 227.9 48 256 48s55.3 5.5 81 16.3c24.8 10.5 47 25.5 66.1 44.6 19.1 19.1 34.1 41.4 44.6 66.1 10.8 25.6 16.3 52.9 16.3 81s-5.5 55.3-16.3 81c-10.5 24.7-25.5 47-44.6 66.1z" fill="currentColor" /><circle cx="256" cy="80" r="8" fill="currentColor" /><circle cx="256" cy="432" r="8" fill="currentColor" /><circle cx="432" cy="256" r="8" fill="currentColor" /><circle cx="80" cy="256" r="8" fill="currentColor" /><circle cx="168" cy="103.6" r="8" fill="currentColor" /><circle cx="344" cy="408.4" r="8" fill="currentColor" /><circle cx="408.4" cy="168" r="8" fill="currentColor" /><circle cx="103.6" cy="344" r="8" fill="currentColor" /><circle cx="103.6" cy="168" r="8" fill="currentColor" /><circle cx="408.4" cy="344" r="8" fill="currentColor" /><circle cx="344" cy="103.6" r="8" fill="currentColor" /><circle cx="168" cy="408.4" r="8" fill="currentColor" /><path d="M269.9 248c-2.9-5-8.1-7.8-13.4-8l-38-63.4c-2.3-3.8-7.2-5-11-2.7s-5 7.2-2.7 11l37.6 62.9c-2.9 4.8-3.2 11.1-.2 16.3 1.5 2.5 3.5 4.5 5.9 5.8V392c0 4.4 3.6 8 8 8s8-3.6 8-8V269.9c7.6-4.5 10.2-14.2 5.8-21.9z" fill="currentColor" /></svg>
                  <p>Lun - Sam 9.30 - 19.00</p>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2803.1564224800727!2d5.589247176840095!3d45.3658384710725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ae43f2964a9e9%3A0xf1e83eb0986b9c67!2sCome%20In%20FEMME!5e0!3m2!1sfr!2sfr!4v1710326573015!5m2!1sfr!2sfr"
                  width="250"
                  height="250"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Carte de localisation de la boutique Come In"
                />
              </article>
              <article className="footer-block__shop">
                <h3>Come In Homme</h3>
                <div className="shop__location">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" d="M22 22H2" /><path strokeLinecap="round" d="M20 22V11M4 22V11" opacity=".5" /><path strokeLinejoin="round" d="M16.528 2H7.472c-1.203 0-1.804 0-2.287.299c-.484.298-.753.836-1.29 1.912L2.49 7.76c-.324.82-.608 1.786-.062 2.479A2 2 0 0 0 6 9a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 0 0 3.571 1.238c.546-.693.262-1.659-.062-2.479l-1.404-3.548c-.537-1.076-.806-1.614-1.29-1.912C18.332 2 17.731 2 16.528 2Z" /><path strokeLinecap="round" d="M9.5 21.5v-3c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C10.598 16 11.065 16 12 16s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75v3" opacity=".5" /></g></svg>
                  <p>3 Rue Adolphe Péronnet, 38500 Voiron</p>
                </div>
                <div className="shop__opening">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm147.1 371.1c-19.1 19.1-41.4 34.1-66.1 44.6-25.6 10.8-52.9 16.3-81 16.3s-55.3-5.5-81-16.3c-24.8-10.5-47-25.5-66.1-44.6C89.8 384 74.8 361.7 64.3 337 53.5 311.3 48 284.1 48 256s5.5-55.3 16.3-81c10.5-24.8 25.5-47 44.6-66.1C128 89.8 150.3 74.8 175 64.3 200.7 53.5 227.9 48 256 48s55.3 5.5 81 16.3c24.8 10.5 47 25.5 66.1 44.6 19.1 19.1 34.1 41.4 44.6 66.1 10.8 25.6 16.3 52.9 16.3 81s-5.5 55.3-16.3 81c-10.5 24.7-25.5 47-44.6 66.1z" fill="currentColor" /><circle cx="256" cy="80" r="8" fill="currentColor" /><circle cx="256" cy="432" r="8" fill="currentColor" /><circle cx="432" cy="256" r="8" fill="currentColor" /><circle cx="80" cy="256" r="8" fill="currentColor" /><circle cx="168" cy="103.6" r="8" fill="currentColor" /><circle cx="344" cy="408.4" r="8" fill="currentColor" /><circle cx="408.4" cy="168" r="8" fill="currentColor" /><circle cx="103.6" cy="344" r="8" fill="currentColor" /><circle cx="103.6" cy="168" r="8" fill="currentColor" /><circle cx="408.4" cy="344" r="8" fill="currentColor" /><circle cx="344" cy="103.6" r="8" fill="currentColor" /><circle cx="168" cy="408.4" r="8" fill="currentColor" /><path d="M269.9 248c-2.9-5-8.1-7.8-13.4-8l-38-63.4c-2.3-3.8-7.2-5-11-2.7s-5 7.2-2.7 11l37.6 62.9c-2.9 4.8-3.2 11.1-.2 16.3 1.5 2.5 3.5 4.5 5.9 5.8V392c0 4.4 3.6 8 8 8s8-3.6 8-8V269.9c7.6-4.5 10.2-14.2 5.8-21.9z" fill="currentColor" /></svg>
                  <p>Lun - Sam 9.30 - 19.00</p>
                </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2803.1506396269806!2d5.590046976840115!3d45.36595517107261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ae578bb85f201%3A0x202f27f3d07717b2!2sCOME%20IN%20HOMME!5e0!3m2!1sfr!2sfr!4v1710325916883!5m2!1sfr!2sfr"
                  width="250"
                  height="250"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Carte de localisation de la boutique Come In Homme"
                />
              </article>
              <article className="footer-block__shop">
                <h3>Pop'In</h3>
                <div className="shop__location">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><path strokeLinecap="round" d="M22 22H2" /><path strokeLinecap="round" d="M20 22V11M4 22V11" opacity=".5" /><path strokeLinejoin="round" d="M16.528 2H7.472c-1.203 0-1.804 0-2.287.299c-.484.298-.753.836-1.29 1.912L2.49 7.76c-.324.82-.608 1.786-.062 2.479A2 2 0 0 0 6 9a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 0 0 3.571 1.238c.546-.693.262-1.659-.062-2.479l-1.404-3.548c-.537-1.076-.806-1.614-1.29-1.912C18.332 2 17.731 2 16.528 2Z" /><path strokeLinecap="round" d="M9.5 21.5v-3c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C10.598 16 11.065 16 12 16s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75v3" opacity=".5" /></g></svg>
                  <p> 2 Rue des Terreaux, 38500 Voiron</p>
                </div>
                <div className="shop__opening">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm147.1 371.1c-19.1 19.1-41.4 34.1-66.1 44.6-25.6 10.8-52.9 16.3-81 16.3s-55.3-5.5-81-16.3c-24.8-10.5-47-25.5-66.1-44.6C89.8 384 74.8 361.7 64.3 337 53.5 311.3 48 284.1 48 256s5.5-55.3 16.3-81c10.5-24.8 25.5-47 44.6-66.1C128 89.8 150.3 74.8 175 64.3 200.7 53.5 227.9 48 256 48s55.3 5.5 81 16.3c24.8 10.5 47 25.5 66.1 44.6 19.1 19.1 34.1 41.4 44.6 66.1 10.8 25.6 16.3 52.9 16.3 81s-5.5 55.3-16.3 81c-10.5 24.7-25.5 47-44.6 66.1z" fill="currentColor" /><circle cx="256" cy="80" r="8" fill="currentColor" /><circle cx="256" cy="432" r="8" fill="currentColor" /><circle cx="432" cy="256" r="8" fill="currentColor" /><circle cx="80" cy="256" r="8" fill="currentColor" /><circle cx="168" cy="103.6" r="8" fill="currentColor" /><circle cx="344" cy="408.4" r="8" fill="currentColor" /><circle cx="408.4" cy="168" r="8" fill="currentColor" /><circle cx="103.6" cy="344" r="8" fill="currentColor" /><circle cx="103.6" cy="168" r="8" fill="currentColor" /><circle cx="408.4" cy="344" r="8" fill="currentColor" /><circle cx="344" cy="103.6" r="8" fill="currentColor" /><circle cx="168" cy="408.4" r="8" fill="currentColor" /><path d="M269.9 248c-2.9-5-8.1-7.8-13.4-8l-38-63.4c-2.3-3.8-7.2-5-11-2.7s-5 7.2-2.7 11l37.6 62.9c-2.9 4.8-3.2 11.1-.2 16.3 1.5 2.5 3.5 4.5 5.9 5.8V392c0 4.4 3.6 8 8 8s8-3.6 8-8V269.9c7.6-4.5 10.2-14.2 5.8-21.9z" fill="currentColor" /></svg>
                  <p>Lun - Sam 9.30 - 19.00</p>
                </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2803.145010377508!2d5.589985076840073!3d45.36606877107249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ae513896f9329%3A0xcb89384903cdb16e!2sPOP&#39;IN!5e0!3m2!1sfr!2sfr!4v1710326493958!5m2!1sfr!2sfr"
                  width="250"
                  height="250"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Carte de localisation de la boutique Pop'In"
                />
              </article>
            </div>
          </article>
          <article className="footer__block">
            <h2>INSTAGRAM</h2>
            <div className="img__insta">
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_1.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_2.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_3.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
            </div>
            <div className="img__insta">
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_4.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_5.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_6.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
            </div>
            <div className="img__insta">
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_7.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_8.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
              <Link to={instaLink} aria-label='Lien vers intagram' target="_blank">
                <img src="/assets/images/instagram/instagram_9.jpg" alt="mannequin avec un haut blanc design couper en plusieurs images" />
                <div className="icon__magnifer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14" /></svg>
                </div>
              </Link>
            </div>
          </article>
        </div >
        <hr />
        <div className="footer__bottom">
          <p><Link to="https://www.jv-design-et-tech.fr" target="_blank" aria-label='Lien vers le site des réalisateur du site'>JV Design & Tech</Link> - COPYRIGHT © 2024</p>
          <div className="footer-bottom__icon">
            <Link to="tel:+336847447" aria-label='Contactez-moi à ce numéro'> 04.76.07.83.15</Link>
            <Link to={instaLink} target="_blank" aria-label='Voici mon instagram principal'>
              <span><i className="ri-instagram-line"></i></span>
            </Link>
            <Link to={fbLink} target="_blank" aria-label='Voici mon facebook principal'>
              <span><i className="ri-facebook-line"></i></span>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
