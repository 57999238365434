import Layout from "../components/layout";
import Boxes from "../components/boxes";
import Banner from "../components/banner";
import CarouselNewClothes from '../components/carouselNews';
import LogoCarousel from '../components/carouselLogo';
import InfoCards from '../components/infoCards';
// import ScrollTextBanner from "../components/scroll-banner";
import information from '../fixtures/information.json';
import logo from '../fixtures/carouselLogo.json';

const Popin = () => {
  return (
    <Layout fbLink="https://www.facebook.com/Popinvoiron" instaLink="https://www.instagram.com/popin_voiron">
      <main className="popin">
        <Banner
          bgimage={require("../styles/assets/banner_background_pop_in.webp")}
          bgimageSmall={require('../styles/assets/banner_background_pop_in_mobile.webp')}
          color="#000"
          title="POP'IN"
        />
    {/* <div className="banner-text">
      <ScrollTextBanner text="Des nouveautés en permanence, restez connectez !" />
    </div> */}
        <div className="cards">
          <div className="cards-shop">
            <img alt="come-in" src="/assets/images/shop_popin.jpg" />
            <article className="text-card">
              <h3>POP'IN VETEMENT & ACCESSOIRE </h3>
              <p className="descr-shop">
                POP'IN est une boutique spécialisée dans le prêt-à-porter et les accessoires féminin. Découvrez en boutique, nos pièces Pieces, VILA et Only.
                <br></br>Pour parfumer votre intérieur ou simplement pour offrir, nous vous proposons des bougies et diffuseur de parfum de la marque Durance.
                <br></br> Découvrez notre large
                selection en magasin , avec une séléction au top des tendances
                et en plus chaque semaine nous reçevons de la nouveauté afin de
                repondre au plus près de vos envies !
              </p>
            </article>
          </div>
        </div>
        <section className='work-with'>
          <hr />
          <h3>Nous travaillons avec</h3>
          <LogoCarousel logo={logo.popIn} />
          <hr />
        </section>

        <section className="cards-categories">
          <h2> Découvrez notre magasin </h2>
          <Boxes info={information.popin} />
        </section>

        <article className='news'>
          <h3>Nouveautés de la collection printemps 2024</h3>
          <CarouselNewClothes information={information} />
        </article>

        <div className="contact-info__style">
          <article className="contact-infos">
            <h3 className="contact-infos__title">Contact & infos</h3>
            <InfoCards />
          </article>
        </div>
      </main>
    </Layout>
  )
};
export default Popin;


